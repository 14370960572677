// React
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Workbook from "react-excel-workbook";

// Imagenes
import OrderIcon from "../../../assets/img/ordenar.svg";
import FilterIcon from "../../../assets/img/filtrar.svg";
import AlertIcon from "../../../assets/img/alert.svg";
import Plantilla1 from "../../../assets/img/Plantilla1.png";
import Plantilla2 from"../../../assets/img/Plantilla2.png";
import BackIcon from "../../../assets/img/BackIcon.svg";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";
import CloseIcon from "../../../assets/img/closeFilter.svg";

// import { allCompaniesData } from "./companiesData";
import { createData, headers } from "./helpersExcel";

// Material
import { CircularProgress, Grid, styled } from "@material-ui/core";
import { Modal, useMediaQuery, Badge } from "@material-ui/core";

// Components
import CardReport from "../../molecules/Card/CardReport";
import EmptyState from "../../molecules/EmptyState";
import Card from "../../molecules/Card";
import Paginations from "../../molecules/Paginations/Paginations";
import { Button } from "../../atoms/Button";
import CardSkeleton from "../../molecules/Skeleton/CardSkeleton";
import { SkeletonCardReport } from "../../molecules/Skeleton/SkeletonCardReport";
import { EmptyStateBuo } from "../../molecules/EmptyState/EmptyStateBuo";
import Header from "../../molecules/Header";
import Filter from "../Filters/CIDFilters";

// Api
import {
  getCompanies,
  deleteCompanyApi,
  getAllCompaniesForCSV,
  getPagination,
} from "../../../api/company";
import {
  getCompaniesByIndustry,
  getCompaniesBySubtech,
  getCompaniesByMercado,
  getCompaniesFilterApi,
} from "../../../api/filtersCompanies";
import {
  getReports,
  deleteReport,
  getClients,
  getSectorsReport,
} from "../../../api/report";
import {
  getReportsByClient,
  getReportsFilterApi,
} from "../../../api/filtersReports";

import { PDF_PATH } from "../../../api/config";

//HOOKS
import useCardList from "../../../hooks/useCardList";
import Order from "./Order";
import { DownloadModal } from "../../molecules/Modal/DownloadModal";

import { ExcelFileCreator } from "./ExcelFileCreator";
import useAuth from "../../../hooks/useAuth";

require("dotenv").config();

const ENV = process.env.NODE_ENV || 'production';

export function CardList({
  type,
  titulo,
  userId,
  isCollapsed,
  linkSearch,
  linkAdd,
}) {
  const {
    isOpen,
    setIsOpen,
    isLoading,
    setIsLoading,
    modalBody,
    setModalBody,
    dataArray,
    setDataArray,
    numResult,
    setNumResult,
    filterMarket,
    setFilterMarket,
    filterSector,
    setFilterSector,
    filterClient,
    setFilterClient,
    orderByDate,
    setOrderByDate,
    orderByAlfa,
    setOrderByAlfa,
    isThereData,
    setIsThereData,
    order,
    setOrder,
    sectores,
    setSectores,
    mercados,
    setMercados,
    clientes,
    setClientes,
    filter,
    setFilter,
  } = useCardList({
    initialisOpen: false,
    initalisLoading: false,
    initialmodalBody: "",
    initaldataArray: [],
    initialnumResult: 0,
    initalfilterMarket: "",
    initialfilterSector: "",
    initalfilterClient: "",
    initialorderByDate: 0,
    initalorderByAlfa: 0,
    initialisThereData: true,
    initalorder: false,
    initialsectores: [],
    initalmercados: [],
    initalclientes: [],
    initialfilter: false,
  });

  const user = useAuth();

  const [globalFilterSector, setGlobalFilterSector] = useState([]);
  const [globalFilterTech, setGlobalFilterTech] = useState([]);
  const [globalFilterSubtech, setGlobalFilterSubtech] = useState([]);
  const [globalFilterMercado, setGlobalFilterMercado] = useState([]);
  const [globalFilterIndustria, setGlobalFilterIndustria] = useState([]);
  const [globalFilterClient, setGlobalFilterClient] = useState([]);

  const [modalDownload, setModalDownload] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const [allCompanies, setAllCompanies] = useState([]);
  // console.log(allCompaniesData, "companies daaataaaaaaaa");
  const [activateDownload, setActivateDownload] = useState(false);
  const [cleanedDataForExcel, setCleanedDataForExcel] = useState([]);
  const [companiesLength, setCompaniesLength] = useState();
  // Status
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  // Variables
  const resultados = 10;

  //Get current post
  const indexOfLastPost = currentPage * resultados;
  const indexOfFirstPost = indexOfLastPost - resultados;

  const msj = (
    <>
      <div className="d-flex justify-content-center mb-4">
        <img src={SuccessIcon} alt="Success Icon" />
      </div>
      <p className="mb-4 a-text-medium-second-neutral-90 text-center">
        Se ha eliminado la empresa correctamente
      </p>
      <div className="m-auto w-50" onClick={() => setIsOpen(false)}>
        <Button
          type="button"
          classes="a-button-primary1 text-small"
          height={36}
          width="100%"
          text="Aceptar"
        />
      </div>
    </>
  );

  function Reload() {
    getPagination(currentPage - 1)
        .then((res) => {
          setDataArray(res.data.companies);
          setCompaniesLength(res.data.length.empresas);
          setNumResult(res.data.companies.slice(0, resultados).length);
          setIsLoading(true);
          setIsThereData(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(true);
          // setDataArray(null)
          setIsThereData(false);
      });
  }

  // Peticiones
  useEffect(() => {
    if (type == "company") {
      // const sectors = getSectors(userId);
      // sectors.then((result) => {
      //   if (result.status === 200) {
      //     setSectores(result.data.sectores);
      //   }
      // });
      // const markets = getTechnology(userId);
      // markets.then((result) => {
      //   if (result.status === 200) {
      //     setMercados(result.data.technology);
      //   }
      // });

      getPagination(0)
        .then((res) => {
          setDataArray(res.data.companies);
          setCompaniesLength(res.data.length.empresas);
          setNumResult(res.data.companies.slice(0, resultados).length);
          setIsLoading(true);
          setIsThereData(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(true);
          // setDataArray(null)
          setIsThereData(false);
        });
    } else {
      // const sectors = getSectorsReport(userId);
      // sectors.then((result) => {
      //   if (result.status === 200) {
      //     setSectores(result.data.sectors);
      //   }
      // });
      getReports(userId)
        .then((res) => {
          console.log(res.data.reports);
          setDataArray(res.data.reports);
          setNumResult(res.data.reports.slice(0, resultados).length);
          setIsLoading(true);
          setIsThereData(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(true);
          //setDataArray(null)
          setIsThereData(false);
        });
      // const clients = getClients(userId);
      // clients.then((result) => {
      //   if (result.status === 200) {
      //     setClientes(result.data.clientes);
      //   }
      // });
    }
  }, []);

  useEffect(() => {
    if (globalFilterTech.length > 0 || globalFilterSector.length > 0) {
      handleFilter().catch((error) => console.log(error));
    } else if (globalFilterMercado.length > 0) {
      handleFilterMercados().catch((error) => console.log(error));
    } else if (globalFilterIndustria.length > 0) {
      handleFilterIndustria().catch((error) => console.log(error));
    } else if (globalFilterClient.length > 0) {
      handleFilterClients().catch((err) => console.log(err));
    } else if (globalFilterSubtech.length > 0) {
      handleFilterSubtech().catch((err) => console.log(err));
    } else {
      if (type == "company") {
        // getCompanies(userId)
        //   .then((res) => {
        //     setDataArray(res.data.companies);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      } else {
        getReports(userId)
          .then((res) => {
            setDataArray(res.data.reports);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [
    globalFilterTech,
    globalFilterSector,
    globalFilterClient,
    globalFilterMercado,
    globalFilterIndustria,
    globalFilterSubtech,
  ]);

  const [error, setError] = useState(false);

  let dataCSV = [];

  const handleClickReporte = () => {
    setModalDownload(true);
    setFetchingData(true);
    getAllCompaniesForCSV()
      .then((res) => {

        setAllCompanies(res.data.company);
        setCleanedDataForExcel(createData(dataCSV, res.data.company));
        setFetchingData(false);
        setActivateDownload(true);
      })
      .catch((err) => {
        setError(true);
        setFetchingData(false);
        console.log(err);
      });

    createData(dataCSV, allCompanies);
  };

  const unselectLabel = (label, state, setState) => {
    console.log(state);
    console.log(label);
    if (state.filter((elm) => elm.name === label.name).length > 0) {
      let input = document.getElementById(`${label.name}`);
      console.log(input);
      input.checked = false;
      console.log(input);
      let aux = state.filter((el) => {
        return el.name !== label.name;
      });
      setState(aux);
    }
  };

  const unselectLabelAnalisis = (label, state, setState) => {
    if (state.filter((elm) => elm.name === label.name).length > 0) {
      let input = document.getElementById(`${label.id}`);
      input.checked = false;
      let aux = state.filter((el) => {
        return el.name !== label.name;
      });
      setState(aux);
    }
  };

  // Funciones
  // const handleChangeSearchReport = (e) => {
  //   setSearchField(e.target.value);
  //   if (dataArray !== undefined) {
  //     setNumResult(
  //       dataArray.filter((report) =>
  //         report.title.toLowerCase().includes(e.target.value.toLowerCase())
  //       ).length
  //     );
  //     setCurrentPage(1);
  //   }
  // };
  // const handleChangeSearchCompany = (e) => {
  //   setSearchField(e.target.value);
  //   if (dataArray !== undefined) {
  //     setNumResult(
  //       dataArray.filter((company) =>
  //         company.name.toLowerCase().includes(e.target.value.toLowerCase())
  //       ).length
  //     );
  //     setCurrentPage(1);
  //   }
  // };

  function paginate(page) {
    setCurrentPage(page);
    // getPagination(currentPage);
    dataArray.length < page * resultados
      ? setNumResult(dataArray.length)
      : setNumResult(page * resultados);
  }

  useEffect(() => {
    if(type === "company"){
      getPagination(currentPage - 1).then(res => {
        setDataArray(res.data.companies);
      })
    }else {

    }
    
    
  }, [currentPage])

  function handleOpen(id) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className="d-flex justify-content-center mb-4">
          <img src={AlertIcon} alt="Warning" />
        </div>
        <p className="mb-4 a-text-medium-second-neutral-90 text-center">
          No se podrán deshacer los cambios.
          <br />
          ¿Seguro que deseas eliminar{" "}
          {type == "company" ? "la empresa" : "el análisis"}?
        </p>
        <div className="d-flex justify-content-center">
          <div className="mr-3 w-100" onClick={() => setIsOpen(false)}>
            <Button
              type="button"
              classes="a-button-primary1 text-small"
              height={36}
              width="100%"
              text="Cancelar"
            />
          </div>
          <div onClick={() => deleteObject(id)} className="w-100">
            <Button
              type="button"
              classes="a-button-light text-small"
              height={36}
              width="100%"
              text="Aceptar"
            />
          </div>
        </div>
      </>
    );
  }
  //Andrea

  const handleBack = () => {
    // window.location.href = "/cid/company";
    setIsOpen(false);
  };

  function fullSelectView(id) {
    setIsOpen(true);
    setModalBody(
      <>
      <div className="select-pdf-modal">
        <div>
            <img
                src={BackIcon}
                alt="Regresar"
                className="nav-back"
                onClick={handleBack}
            />
            <p className="frame-back a-text-medium-second-neutral-90 nav-back" onClick={handleBack}> regresar </p> 
        </div>
        <div>
            <p className="p-1-text a-text-medium-second-neutral-90">Plantilla 1</p>
            <a href={`${PDF_PATH}/get-one-page-pdf/?companyId=${id}&empresa= a`}  target="_blank">
            <img 
            className="p-1-frame-select" 
            src={Plantilla1} 
            alt="Plantilla 1"
            />
            </a>
        </div>
        <div>  
            <p className="p-2-text a-text-medium-second-neutral-90">Plantilla 2</p>
            <a href={`${PDF_PATH}/get-one-page/?companyId=${id}&empresa=a`}  target="_blank">
            <img 
            className="p-2-frame-select" 
            src={Plantilla2} 
            alt="Plantilla 2" 
            />
            </a>
        </div>
      </div>     
      </>
    );
  }

  function miniSelectView(id) {
    setIsOpen(true);
    setModalBody(
      <>
      <div className="select-pdf-modal-m" >
        <div>
            <p className="mp-1-text a-text-medium-second-neutral-90">Plantilla 1</p>
            <a href={`${PDF_PATH}/get-one-page-pdf/?companyId=${id}&empresa= a`}  target="_blank">
            <img 
            className="mp-1-frame-select" 
            src={Plantilla1} 
            alt="Plantilla 1" 
            />
            </a>
        </div>
        <div>  
            <p className="mp-2-text a-text-medium-second-neutral-90">Plantilla 2</p>
            <a href={`${PDF_PATH}/get-one-page/?companyId=${id}&empresa=a`}  target="_blank">
            <img 
            className="mp-2-frame-select" 
            src={Plantilla2} 
            alt="Plantilla 2" 
            />
            </a>
        </div>
   </div>     
      </>
    );
  }

  function deleteObject(id) {
    console.log(id)
    type == "company"
      ? deleteCompanyApi(userId, id)
          .then((result) => {
            setIsLoading(false);
            if (result.status == 200) {
              setModalBody(msj);
              Reload();
            }
          })
          .catch((err) => console.log(err))
      : deleteReport(userId, id)
          .then((result) => {
            setIsLoading(false);
            if (result.status == 200) {
              setModalBody(msj);
              getReports(userId)
                .then((res) => {
                  setDataArray(res.data.reports);
                  setNumResult(res.data.reports.slice(0, resultados).length);
                  setIsLoading(true);
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(true);
                  setDataArray(null);
                });
            }
          })
          .catch((err) => console.log(err));
  }

  const handleFilter = async () => {
    let filterSector = [...globalFilterSector];
    let filterMarket = [...globalFilterTech];
    if (type == "company") {
      const { data } = await getCompaniesFilterApi(
        userId,
        filterSector,
        filterMarket
      );
      setDataArray(data.companies);
    } else {
      const { data } = await getReportsFilterApi(
        userId,
        filterSector,
        filterMarket
      );
      setDataArray(data.reports);
    }
  };

  const handleFilterClients = async () => {
    let filterClients = [...globalFilterClient];
    const { data } = await getReportsByClient(userId, filterClients);
    setDataArray(data.reports);
  };

  const handleFilterMercados = async () => {
    let filterMercados = [...globalFilterMercado];
    const { data } = await getCompaniesByMercado(userId, filterMercados);
    setDataArray(data.companies);
  };

  const handleFilterIndustria = async () => {
    let filterIndustry = [...globalFilterIndustria];
    const { data } = await getCompaniesByIndustry(userId, filterIndustry);
    setDataArray(data.companies);
  };

  const handleFilterSubtech = async () => {
    let filterSubtech = [...globalFilterSubtech];
    const { data } = await getCompaniesBySubtech(userId, filterSubtech);
    setDataArray(data.companies);
  };

  // const c = getCompaniesFilterApi(
  //             userId,
  //             sectorsString,
  //             filterMarket,
  //             orderByDate,
  //             orderByAlfa
  //           );
  //           c.then((result) => {
  //             setIsLoading(true);
  //             setDataArray(result.data.companies);
  //             setNumResult(result.data.companies.length);
  //           }).catch((err) => {
  //             setIsLoading(true);
  //             console.log(err);
  //           });

  let filteredData = [];
  if (dataArray !== undefined) {
    if (type === "company") {
      filteredData = dataArray.filter((company) =>
        company.name.toLowerCase().includes(searchField.toLowerCase())
      );
    } else {
      filteredData = dataArray.filter((report) =>
        report.title.toLowerCase().includes(searchField.toLowerCase())
      );
    }
  }

  var currentData = filteredData.slice(indexOfFirstPost, indexOfLastPost);
  // console.log(currentData, "currentData");

  const history = useHistory();
  const location = useLocation();

  function handleChangeHeader(e) {
    if (type == "company") {
      history.push({
        pathname: "/cid/search-company-alternative",
        state: {
          value: e.target.value,
          location: location.pathname,
        },
      });
    } else {
      history.push({
        pathname: "/cid/search-analysis",
        state: {
          value: e.target.value,
          location: location.pathname,
        },
      });
    }
  }

  return (
    <>
      <Header
        isCollapsed={isCollapsed}
        link={linkSearch}
        text={type == "company" ? "Buscar empresa" : "Buscar análisis"}
        focus={true}
        onChange={handleChangeHeader}
      />
      {activateDownload && cleanedDataForExcel.length > 0 && (
        <ExcelFileCreator dataCompanies={cleanedDataForExcel} />
      )}
      {/* <ExcelFileCreator dataCompanies={cleanedDataForExcel}/> */}
      <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
        <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
          {isThereData ? (
            <Grid container alignItems="center" justify="center" spacing={0}>
              <Grid xs={10} lg={12}>
                <div className="d-flex justify-content-between">
                  <p className="h3-lg a-text-medium-second-neutral-70 align-self-end">
                    Catálogo de {titulo}
                  </p>

                  {order || filter ? (
                    ""
                  ) : (
                    <div className="d-flex">
                      {/* {type === "company" && (
                        <CSVLink
                          headers={headers}
                          data={dataCSV}
                          filename={"WikiCID-Empresas.csv"}
                        >
                          <Button
                            type="button"
                            classes="a-button-secondary text-small roboto-medium mr-3"
                            width="150px"
                            height="33px"
                            text={"Obtener reporte"}
                          />
                        </CSVLink>
                      )} */}
                      {type === "company" && user.rolId !== 3 && (
                        <Button
                          type="button"
                          classes="a-button-secondary text-small roboto-medium mr-3 br-1 d-none d-lg-block"
                          width="150px"
                          height="33px"
                          text={"Obtener reporte"}
                          onclick={() => handleClickReporte()}
                        />
                      )}

                      {/* {!fetchingData && dataCSV.length > 0 && banderita && (
                        <CSVDownload
                          headers={headers}
                          data={dataCSV}
                          target="_blank"
                          filename={"WikiCID-Empresas.csv"}
                        />
                      )} */}

                      {/* {type === "company" && (
                        <CSVLink
                          headers={headers}
                          data={dataCSV}
                          filename={"WikiCID-Empresas.csv"}
                          onClick={(event, done) => { handleClickReporte(done) }}
                        >
                          <Button
                            type="button"
                            classes="a-button-secondary text-small roboto-medium mr-3 br-1"
                            width="150px"
                            height="33px"
                            text={"Obtener reporte"}
                          />
                        </CSVLink>
                      )} */}
                      {user.rolId === 3 ? (
                        ""
                      ) : (
                        <Link to={linkAdd} className="d-none d-lg-block ">
                          <Button
                            type="button"
                            classes="a-button-blue-1 text-small roboto-regular"
                            width="150px"
                            height="33px"
                            text={
                              type == "company"
                                ? "Añadir empresas"
                                : "Añadir análisis"
                            }
                          />
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                {order || filter ? (
                  ""
                ) : (
                  <>
                    <div className="d-xl-flex justify-content-xl-between mt-4 mb-3">
                      {
                        type === "company" ?
                        <div>
                        <p className="a-text-medium-first-neutral-100 align-self-end text-sm ll text-md-normals text-lg-medium d-xl-none">
                          {`${
                            indexOfLastPost > companiesLength
                              ? companiesLength
                              : indexOfLastPost
                          }`}{" "}
                          / {companiesLength}{" "}
                          <span className="a-text-regular-first-neutral-100">
                            resultados
                          </span>
                        </p>
                        <p className="a-text-medium-first-neutral-100 text-small text-md-normals text-lg-medium d-none d-xl-block">
                          <span className="a-text-regular-first-neutral-100">
                            Mostrando
                          </span>{" "}
                          {`${
                            indexOfLastPost > companiesLength
                              ? companiesLength
                              : indexOfLastPost
                          }`}{" "}
                          <span className="a-text-regular-first-neutral-100">
                            de
                          </span>{" "}
                          {companiesLength}
                        </p>
                      </div> 
                      :
                      <div>
                      <p className="a-text-medium-first-neutral-100 align-self-end text-sm ll text-md-normals text-lg-medium d-xl-none">
                        {`${
                          indexOfLastPost > filteredData.length
                            ? filteredData.length
                            : indexOfLastPost
                        }`}{" "}
                        / {filteredData.length}{" "}
                        <span className="a-text-regular-first-neutral-100">
                          resultados
                        </span>
                      </p>
                      <p className="a-text-medium-first-neutral-100 text-small text-md-normals text-lg-medium d-none d-xl-block">
                        <span className="a-text-regular-first-neutral-100">
                          Mostrando
                        </span>{" "}
                        {`${
                          indexOfLastPost > filteredData.length
                            ? filteredData.length
                            : indexOfLastPost
                        }`}{" "}
                        <span className="a-text-regular-first-neutral-100">
                          de
                        </span>{" "}
                        {filteredData.length}
                      </p>
                    </div> 
                      }
                      
                      
                      <div className="d-flex justify-content-between">
                        <div
                          onClick={() => setOrder(!order)}
                          className="d-flex align-items-center cursor-pointer mr-xl-4"
                        >
                          <img
                            src={OrderIcon}
                            alt="Order"
                            className="img-order"
                          />
                          <p className="ml-2 text-small text-md-normal a-text-regular-first-primary-60">
                            Ordenar
                          </p>
                        </div>
                        <div
                          onClick={() => setFilter(!filter)}
                          className="d-flex align-items-center cursor-pointer"
                        >
                          <Badge
                            badgeContent={
                              globalFilterSector.length +
                              globalFilterTech.length +
                              globalFilterSubtech.length +
                              globalFilterMercado.length +
                              globalFilterIndustria.length +
                              globalFilterClient.length
                            }
                            color="primary"
                          >
                            <img
                              src={FilterIcon}
                              alt="Order"
                              className="img-filter"
                            />
                          </Badge>

                          <p className="ml-2 text-small text-md-normal a-text-regular-first-primary-60">
                            Filtrar
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* Fultrado */}
                <div className={`${filter ? "d-block" : "d-none"}`}>
                  {type == "company" ? (
                    <Filter
                      type={type}
                      onClick={() => {
                        setFilter(!filter);
                      }}
                      filter={filter}
                      userId={userId}
                      globalFilterTech={globalFilterTech}
                      globalFilterSector={globalFilterSector}
                      globalFilterMercado={globalFilterMercado}
                      globalFilterIndustria={globalFilterIndustria}
                      globalFilterSubtech={globalFilterSubtech}
                      setGlobalFilterSector={setGlobalFilterSector}
                      setGlobalFilterTech={setGlobalFilterTech}
                      setGlobalFilterMercado={setGlobalFilterMercado}
                      setGlobalFilterIndustria={setGlobalFilterIndustria}
                      setGlobalFilterSubtech={setGlobalFilterSubtech}
                    />
                  ) : (
                    <Filter
                      type={type}
                      onClick={() => {
                        setFilter(!filter);
                      }}
                      filter={filter}
                      userId={userId}
                      globalFilterTech={globalFilterTech}
                      globalFilterSector={globalFilterSector}
                      setGlobalFilterSector={setGlobalFilterSector}
                      setGlobalFilterTech={setGlobalFilterTech}
                      globalFilterClient={globalFilterClient}
                      setGlobalFilterClient={setGlobalFilterClient}
                    />
                  )}

                  {/* <Filter
                    onChange={filterBy}
                    onClick={() => setFilter(!filter)}
                    sectores={sectores}
                    mercados={mercados}
                    clientes={clientes}
                    filterMarket={filterMarket}
                    filterSector={filterSector}
                    filterClient={filterClient}
                  /> */}
                </div>
                {/* Ordenamiento */}
                <div className={`${order ? "d-block" : "d-none"}`}>
                  <Order
                    // onChange={orderBy}
                    onClick={() => setOrder(!order)}
                    // orderByDate={orderByDate}
                    // orderByAlfa={orderByAlfa}
                  />
                </div>
                {/* Filtro de clientes */}
                {filterClient != 0 ? (
                  <span
                    className={` a-text-medium-second-neutral-100 mb-3 ${
                      !filter && !order ? "d-block" : "d-none"
                    }`}
                  >
                    Clientes filtrados:
                  </span>
                ) : (
                  ""
                )}
                {/* {filterClient != 0
                  ? clientesSelectedLabels.map((r) => (
                      <div
                        className={` mb-3 mr-2 filter-label ${
                          !filter && !order ? "d-initial" : "d-none"
                        }`}
                      >
                        <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                          {r.name}
                        </span>
                        <img
                          src={CloseIcon}
                          className="filter-label-icon"
                          alt="Close"
                          onClick={() => unselectFilter("cliente", r.id)}
                        />
                      </div>
                    ))
                  : ""} */}
                {/* Filtro de sectores */}
                {/* {filterSector != 0 ? (
                  <span
                    className={` a-text-medium-second-neutral-100 mb-3 ${
                      !filter && !order ? "d-block" : "d-none"
                    }`}
                  >
                    Sectores filtrados:
                  </span>
                ) : (
                  ""
                )}
                {filterSector != 0
                  ? sectorsSelectedLabels.map((r) => (
                      <div
                        className={` mb-3 mr-2 filter-label ${
                          !filter && !order ? "d-initial" : "d-none"
                        }`}
                      >
                        <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                          {r.name}
                        </span>
                        <img
                          src={CloseIcon}
                          className="filter-label-icon"
                          alt="Close"
                          // onClick={() => unselectFilter("sector", r.id)}
                        />
                      </div>
                    ))
                  : ""} */}

                {/* {globalFilterSector.length > 0 && (
                  <div>
                    <span
                      className={` a-text-medium-second-neutral-100 mb-3 ${
                        !filter && !order ? "d-block" : "d-none"
                      }`}
                    >
                      Sectores filtrados:
                    </span>
                    {globalFilterSector.map((label) => {
                      return (
                        <div
                          className={` mb-3 mr-2 filter-label ${
                            !filter && !order ? "d-initial" : "d-none"
                          }`}
                          key={label.sector_id}
                        >
                          <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                            {label.name}
                          </span>
                          <img
                            src={CloseIcon}
                            className="filter-label-icon"
                            alt="Close"
                            onClick={
                               type === "company" ?  
                              () =>
                              unselectLabel(
                                label,
                                globalFilterSector,
                                setGlobalFilterSector
                              ) :
                              () =>
                              unselectLabelAnalisis(
                                label,
                                globalFilterSector,
                                setGlobalFilterSector
                              ) 

                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )} */}
                {/* Filtro de Industrias */}
                {/* {globalFilterIndustria.length > 0 && (
                  <div>
                    <span
                      className={` a-text-medium-second-neutral-100 mb-3 ${
                        !filter && !order ? "d-block" : "d-none"
                      }`}
                    >
                      Industrias filtradas:
                    </span>
                    {globalFilterIndustria.map((label) => {
                      return (
                        <div
                          className={` mb-3 mr-2 filter-label ${
                            !filter && !order ? "d-initial" : "d-none"
                          }`}
                          key={label.id}
                        >
                          <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                            {label.name}
                          </span>
                          <img
                            src={CloseIcon}
                            className="filter-label-icon"
                            alt="Close"
                            onClick={
                              () =>
                              unselectLabel(
                                label,
                                globalFilterIndustria,
                                setGlobalFilterIndustria
                              )

                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )} */}
                {/* Filtro de tecnologías */}
                {/* {globalFilterTech.length > 0 && (
                  <div>
                    <span
                      className={` a-text-medium-second-neutral-100 mb-3 ${
                        !filter && !order ? "d-block" : "d-none"
                      }`}
                    >
                      Tecnologías Primarías filtradas:
                    </span>
                    {globalFilterTech.map((label) => {
                      return (
                        <div
                          className={` mb-3 mr-2 filter-label ${
                            !filter && !order ? "d-initial" : "d-none"
                          }`}
                          key={label.id}
                        >
                          <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                            {label.name}
                          </span>
                          <img
                            src={CloseIcon}
                            className="filter-label-icon"
                            alt="Close"
                            onClick={
                              type === "company" ? 
                              () =>
                              unselectLabel(
                                label,
                                globalFilterTech,
                                setGlobalFilterTech
                              )
                              :
                              () => 
                              unselectLabelAnalisis(
                                label,
                                globalFilterTech,
                                setGlobalFilterTech
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )} */}

                {/* Filtro de tecnologías secundarías */}
                {/* {globalFilterSubtech.length > 0 && (
                  <div>
                    <span
                      className={` a-text-medium-second-neutral-100 mb-3 ${
                        !filter && !order ? "d-block" : "d-none"
                      }`}
                    >
                      Tecnologías Secundarías filtradas:
                    </span>
                    {globalFilterSubtech.map((label) => {
                      console.log(label)
                      return (
                        <div
                          className={` mb-3 mr-2 filter-label ${
                            !filter && !order ? "d-initial" : "d-none"
                          }`}
                          key={label.id}
                        >
                          <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                            {label.name}
                          </span>
                          <img
                            src={CloseIcon}
                            className="filter-label-icon"
                            alt="Close"
                            onClick={
                              () =>
                              unselectLabel(
                                label,
                                globalFilterSubtech,
                                setGlobalFilterSubtech
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )} */}

                {/* Filtro de mercados */}
                {/* {globalFilterMercado.length > 0 && (
                  <div>
                    <span
                      className={` a-text-medium-second-neutral-100 mb-3 ${
                        !filter && !order ? "d-block" : "d-none"
                      }`}
                    >
                      Mercados filtradas:
                    </span>
                    {globalFilterMercado.map((label) => {
                      return (
                        <div
                          className={` mb-3 mr-2 filter-label ${
                            !filter && !order ? "d-initial" : "d-none"
                          }`}
                          key={label.id}
                        >
                          <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                            {label.name}
                          </span>
                          <img
                            src={CloseIcon}
                            className="filter-label-icon"
                            alt="Close"
                            onClick={
                              () =>
                              unselectLabelAnalisis(
                                label,
                                globalFilterMercado,
                                setGlobalFilterMercado
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )} */}

                {/* Filtro de clientes */}
                {/* {globalFilterClient.length > 0 && (
                  <div>
                    <span
                      className={` a-text-medium-second-neutral-100 mb-3 ${
                        !filter && !order ? "d-block" : "d-none"
                      }`}
                    >
                      Clientes filtrados:
                    </span>
                    {globalFilterClient.map((label) => {
                      return (
                        <div
                          className={` mb-3 mr-2 filter-label ${
                            !filter && !order ? "d-initial" : "d-none"
                          }`}
                          key={label.id}
                        >
                          <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                            {label.name}
                          </span>
                          <img
                            src={CloseIcon}
                            className="filter-label-icon"
                            alt="Close"
                            onClick={() =>
                              unselectLabel(
                                label,
                                globalFilterClient,
                                setGlobalFilterClient
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )} */}
                {/* {filterMarket != 0 ? (
                  <span
                    className={` a-text-medium-second-neutral-100 mb-3 ${
                      !filter && !order ? "d-block" : "d-none"
                    }`}
                  >
                    Tecnologías filtradas:
                  </span>
                ) : (
                  ""
                )} */}
                {/* {filterMarket != 0
                  ? mercadosSelectedLabels.map((r) => (
                      <div
                        className={` mb-3 mr-2 filter-label ${
                          !filter && !order ? "d-initial" : "d-none"
                        }`}
                      >
                        <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                          {r.name}
                        </span>
                        <img
                          src={CloseIcon}
                          className="filter-label-icon"
                          alt="Close"
                          onClick={() => unselectFilter("mercado", r.id)}
                        />
                      </div>
                    ))
                  : ""} */}
                {/* orden de fecha */}
                {/* {orderByDate != 0 || orderByAlfa != 0 ? (
                  <span
                    className={` a-text-medium-second-neutral-100 mb-3 ${
                      !order && !filter ? "d-block" : "d-none"
                    }`}
                  >
                    Ordenado por:
                  </span>
                ) : (
                  ""
                )} */}
                {/* {orderByDate != 0 ? (
                  <div
                    className={` mb-3 mr-2 filter-label ${
                      !order && !filter ? "d-initial" : "d-none"
                    }`}
                  >
                    <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                      Fecha de registro
                    </span>
                    <img
                      src={CloseIcon}
                      className="filter-label-icon"
                      alt="Close"
                      onClick={() => unselectFilter("OrderByDate")}
                    />
                  </div>
                ) : (
                  ""
                )} */}
                {/* orden alfabético */}
                {/* {orderByAlfa != 0 ? (
                  <div
                    className={`mt-2 mb-3 mr-2 filter-label ${
                      !order && !filter ? "d-initial" : "d-none"
                    }`}
                  >
                    <span className="mr-2 a-text-regular-second-neutral-60 mb-0">
                      Alfabéticamente
                    </span>
                    <img
                      src={CloseIcon}
                      className="filter-label-icon"
                      alt="Close"
                      onClick={() => unselectFilter("orderByAlfa")}
                    />
                  </div>
                ) : (
                  ""
                )} */}
              </Grid>
              {/* <Grid container alignItems="center" justify="center" spacing={0} className={!filter && !order ? "d-block" : "d-none"}> */}
              <Grid
                xs={10}
                lg={12}
                className={`mt-3 px-lg-0 ${
                  !filter && !order ? "" : "d-none"
                }`}
              >
                {type == "company" ? (
                  isLoading ? (
                    dataArray.length !== 0 ? (
                      dataArray.map((res, idx) => (
                        <Card
                          key={idx}
                          noEdit={user.rolId === 3}
                          image={res.logo_link}
                          title={res.name}
                          ilustration={res.ilustration_link}
                          link={{
                            pathname: "/cid/company/" + res.company_id,
                            state: {
                              array: currentData.map((res) => res.company_id),
                              index: idx,
                              location: 1,
                            },
                          }}
                          linkEdit={"/cid/update-company/" + res.company_id}
                          linkPdf={`${PDF_PATH}/get-one-page/?companyId=${res.company_id}&empresa=${res.name.replace(' ', '_')}`}
                          linkPtt={`${PDF_PATH}/get-one-page-presentacion/?companyId=${res.company_id}&empresa=${res.name.replace(' ', '_')}`}
                          linkPortDocForm={`${PDF_PATH}/get-one-page-pdf/?companyId=${res.company_id}&empresa=${res.name.replace(' ', '_')}`}
                          id={res.company_id}
                          userId={userId}
                          onClick={handleOpen}
                          fullView={fullSelectView}
                          miniView={miniSelectView}
                        />
                      ))
                    ) : (
                      <EmptyState />
                    )
                  ) : (
                    <CardSkeleton num={resultados} />
                  )
                ) : isLoading ? (
                  currentData.length !== 0 ? (
                    currentData.map((res, idx) => (
                      <CardReport
                        key={idx}
                        noEdit={user.rolId === 3}
                        name={res.title}
                        linkPdf={res.file_url}
                        id={res.report_id}
                        date={res.created_at}
                        onClick={handleOpen}
                        linkEdit={"/cid/update-analysis/ " + res.report_id}
                      />
                    ))
                  ) : (
                    <EmptyState />
                  )
                ) : (
                  <SkeletonCardReport num={resultados} />
                )}
                <Paginations
                  postPerPage={resultados}
                  totalCompanies={type === "company" ? companiesLength : filteredData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </Grid>
              {/* </Grid> */}
            </Grid>
          ) : type == "company" ? (
            <EmptyStateBuo
              titulo="empresas"
              link="/cid/add-company"
              subTitulo="una"
            />
          ) : (
            // <Modal
            // open={true}
            // aria-labelledby="simple-modal-title"
            // aria-describedby="simple-modal-description"
            // >
            //   <div className="modal-download">
            //   <DownloadModal />
            //   </div>

            // </Modal>
            <EmptyStateBuo
              titulo="análisis"
              link="/cid/add-analysis"
              subTitulo="uno"
            />
          )}
        </div>
        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal">{modalBody}</div>
        </Modal>

        <Modal
          open={modalDownload}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal-download">
            <DownloadModal
              error={error}
              setModalDownload={setModalDownload}
              fetchingData={fetchingData}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
